import * as React from "react";
import {useEffect} from "react";
import Spinner from "../animation/Spinner";
import useDashboardDetails from "./getDashboardDetails";
import queryString from "query-string";
import {ErrorType} from "../../util/api";
import {navigate} from "gatsby";
import LoadingText from "../typography/LoadingText";
import TwoColumn from "../layout/TwoColumn";
import SocialStream from "../social/SocialStream";
import SummaryStream from "../social/SummaryStream";
import Topics from "../social/Topics";
import DashboardMetrics from "../graph/DashboardMetrics";
import TopLinks from "../social/TopLinks";
import ContentStream from "../social/ContentStream";
import ContentDashboardMetrics from "../graph/ContentDashboardMetrics";
import ContentTopics from "../social/ContentTopics";
import {GtagEvent, sendGtagEvent} from "../user/GtagEvent";

const DashboardViewPage = (props) => {

    const dashboardId = queryString.parse(props.location.search).id ?? '0';

    const {
        isLoading,
        error,
        data,
        execute
    } = useDashboardDetails(queryString.parse(props.location.search).id as string | undefined);

    if (
        error === ErrorType.InvalidSymbol ||
        error === ErrorType.NotFound ||
        execute === null
    ) {
        if (typeof window !== "undefined") {
            sendGtagEvent(GtagEvent.VISIT_DASHBOARD, {'type': 'failure', 'info': '404'})
            navigate("/404");
        }
        if (execute === null) return null;
    } else if (error) {
        if (typeof window !== "undefined") {
            sendGtagEvent(GtagEvent.VISIT_DASHBOARD, {'type': 'failure', 'info': '500'})
            navigate("/500");
        }
    }

    useEffect(() => {
        execute();
    }, [execute]);

    const getDashboard = () => {
        if (isLoading) {
            return (
                <div className={"flex items-center"}>
                    <div className={"m-auto pt-8 pb-8"}>
                        <Spinner width={100} height={100} />
                    </div>
                </div>
            )
        }

        if (!data || error) {
            return (
                <div>ERROR no dashboards</div>
            )
        }

        return (
            <div>
                <div className={"text-2xl px-6 pb-2 pt-4"}>
                    {data.frontEndLabel}
                </div>
            </div>
        )


    }

    const getDashboardHeading = () => {
        if (isLoading) {
            return (
                <div>
                    <LoadingText height={50} width={150} />
                </div>
            )
        } else if (!data || error) {
            return (
                <div>ERROR no dashboards</div>
            )
        } else {
            sendGtagEvent(GtagEvent.PAGE_VIEW, {'dashboard_id': data.id})
            return (
                <div>
                    {data.frontEndLabel}
                </div>
            )
        }
    }

    const commonComponentPropts = {
        dashboardId: parseInt(dashboardId),
        isSubjectLoading: isLoading,
        subjectData: data
    }

    return (
        <>
            <div className={"text-2xl px-6 pb-2 pt-4"}>
                {getDashboardHeading()}
            </div>
            <TwoColumn>
                <div>
                    {
                        isLoading || data?.isTwitterLegacy
                            ? (<DashboardMetrics {...commonComponentPropts} />)
                            : (<ContentDashboardMetrics {...commonComponentPropts} />)
                    }
                    {
                        isLoading || data?.isTwitterLegacy
                            ? (<SocialStream {...commonComponentPropts} />)
                            : (<ContentStream {...commonComponentPropts} />)
                    }
                </div>
                <div>
                    {
                        isLoading || data?.isTwitterLegacy
                            ? (<Topics {...commonComponentPropts}/>)
                            : (<ContentTopics {...commonComponentPropts} />)
                    }
                    { data && data?.doEnableTopLinks ? (
                      <TopLinks {...commonComponentPropts} />
                    ) : null }
                    <SummaryStream {...commonComponentPropts} />
                </div>
            </TwoColumn>
        </>
    );
};

export default DashboardViewPage;
