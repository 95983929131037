import React, {ChangeEventHandler, Fragment, MutableRefObject, useEffect, useRef, useState} from "react";
import TileRounded from "../layout/TileRounded";
import {useD3} from "../d3/d3Wrapper";
import * as d3 from "d3";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {ErrorType, getDateFromApiString} from "../../util/api";
import Spinner from "../animation/Spinner";
import {getLargeValue} from "../../util/format";
import {format, isValid, parse} from "date-fns";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import {Popover, Transition} from "@headlessui/react";
import {CalendarIcon} from "@heroicons/react/outline";
import {DayPicker, SelectSingleEventHandler} from "react-day-picker";
import {
    ContentDataType,
    ContentDataTypeMetric,
    ContentMetric,
    ContentTopicMetric,
    getEnabledContentDataTypes
} from "../../models/content";
import {
    GraphIntervalType,
    useGetSubjectContentMetricsResult,
} from "./getContentMetrics";
import {DateTime} from "luxon";

enum GraphSelected {
    MENTION = 'mention',
    TOPIC = 'topics',
    SENTIMENT = 'sentiment',
    DATA_TYPE = 'data_type',
}

enum SubGraphSelected {
    MENTION = 'mention',
    SENTIMENT = 'sentiment'
}

type GraphInfo = {
    label: string;
    dataKey: GraphSelected;
    selectKey: GraphSelected;
}

type SubGraphInfo = {
    label: string;
    dataKey: SubGraphSelected;
    selectKey: SubGraphSelected;
}

const graphInfoList: GraphInfo[] = [
    {
        label: 'Mentions',
        dataKey: GraphSelected.MENTION,
        selectKey: GraphSelected.MENTION,
    },
    {
        label: 'Topics',
        dataKey: GraphSelected.MENTION,
        selectKey: GraphSelected.TOPIC,
    },
    {
        label: 'Data Source',
        dataKey: GraphSelected.MENTION,
        selectKey: GraphSelected.DATA_TYPE,
    },
    {
        label: 'Sentiment',
        dataKey: GraphSelected.MENTION,
        selectKey: GraphSelected.SENTIMENT,
    },
]


const subGraphInfoList: SubGraphInfo[] = [
    {
        label: 'Mentions',
        dataKey: SubGraphSelected.MENTION,
        selectKey: SubGraphSelected.MENTION,
    },
    {
        label: 'Sentiment',
        dataKey: SubGraphSelected.SENTIMENT,
        selectKey: SubGraphSelected.SENTIMENT,
    },
]

type ProcessedMetric = {
    date: Date;
    mention: number;
    sentiment: number;
    sentimentPositive: number;
    sentimentNegative: number;
    topics: ContentTopicMetric[] | null;
    dataTypes: ContentDataTypeMetric[] | null;
}

const getGraphInfo = (typeSelected: GraphSelected) => {
    return graphInfoList.find(graphInfo => graphInfo.selectKey === typeSelected)
}

const getSubGraphInfo = (typeSelected: SubGraphSelected) => {
    return subGraphInfoList.find(graphInfo => graphInfo.selectKey === typeSelected)
}

const processMetric = (metric: ContentMetric, isWeekly = false): ProcessedMetric => {
    let sentiment = 0
    let sentimentPos = 0
    let sentimentNeg = 0
    if (metric.m) {
        sentimentPos = Math.floor((metric.pos / metric.m) * 100)
        sentimentNeg = Math.floor((metric.neg / metric.m) * 100)
        sentiment = sentimentPos - sentimentNeg
    }
    let date_str = metric.t
    if (isWeekly) {
        date_str = `${date_str}-1`
    }

    return {
        date: DateTime.fromISO(date_str).toJSDate(),
        mention: metric.m,
        sentiment: sentiment,
        sentimentPositive: sentimentPos,
        sentimentNegative: sentimentNeg,
        topics: metric?.tps ? metric.tps : null,
        dataTypes: metric?.dt ? metric.dt : null,
    }
}

const posSentimentColor = "rgb(134 239 172)"
const negSentimentColor = "rgb(252 165 165)"
const neutralSentimentColor = "rgb(212 212 212)"
const topicColor = "rgb(191 219 254)"


const ContentDashboardMetrics = ({ isSubjectLoading, dashboardId, subjectData }: DashboardViewProps) => {
    const enabledDataTypes: ContentDataType[] = getEnabledContentDataTypes(subjectData!)
    const [doShowLoading, setDoShowLoading] = useState<boolean>(true);
    const chartHeight = 250;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [ chartType, setChartType ] = useState<GraphSelected>(GraphSelected.MENTION)
    const [ chartInterval, setChartInterval ] = useState<GraphIntervalType>(GraphIntervalType.DAILY)
    const [ chartIntervalDropdown, setChartIntervalDropdown ] = useState<GraphIntervalType.DAILY | GraphIntervalType.WEEKLY | GraphIntervalType.HOURLY>(GraphIntervalType.DAILY)
    const [ hourlyData, setHourlyData ] = useState<ProcessedMetric[]>([])
    const [ dailyData, setDailyData ] = useState<ProcessedMetric[]>([])
    const [ weeklyData, setWeeklyData ] = useState<ProcessedMetric[]>([])
    const [ hourlyDataByDate, setHourlyDataByDate ] = useState<ProcessedMetric[]>([])
    const [ dailyDataByDate, setDailyDataByDate ] = useState<ProcessedMetric[]>([])
    const [ weeklyDataByDate, setWeeklyDataByDate ] = useState<ProcessedMetric[]>([])
    const [ subChartType, setSubChartType ] = useState<SubGraphSelected>(SubGraphSelected.MENTION)
    const [topicId, setTopicId] = useState<number>(0);
    const [dataTypeId, setDataTypeId] = useState<number>(0);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    const [dateApi, setDateApi] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [inputDateValue, setInputDateValue] = useState<string>("");
    const [isDateError, setIsDateError] = useState<boolean>(false);

    const isTopicSelected = () => {
        if (!subjectData) {
            return false;
        }
        if (!topicId) {
            return false;
        }
        const topic = subjectData.topics.find(t => t.id === topicId);
        return !!(topic);
    }

    const isDataTypeSelected = () => {
        if (!subjectData) {
            return false;
        }
        return !!dataTypeId
    }

    const isTopicSentiment = (topic_id: number) => {
        if (!topic_id) {
            return false;
        }
        if (!subjectData) {
            return false;
        }
        if (!subjectData.doTopicSentiment) {
            return false;
        }
        const topic = subjectData.topics.find(t => t.id === topic_id);
        return !!(topic && topic.doSentimentTopic);
    }

    const {
        isLoading: isLoadingDaily,
        error: errorDaily,
        data: responseDaily,
        execute: executeDaily
    } = useGetSubjectContentMetricsResult(GraphIntervalType.DAILY)

    const {
        isLoading: isLoadingHourly,
        error: errorHourly,
        data: responseHourly,
        execute: executeHourly
    } = useGetSubjectContentMetricsResult(GraphIntervalType.HOURLY)

    const {
        isLoading: isLoadingWeekly,
        error: errorWeekly,
        data: responseWeekly,
        execute: executeWeekly
    } = useGetSubjectContentMetricsResult(GraphIntervalType.WEEKLY)

    const {
        isLoading: isLoadingDailyByDate,
        error: errorDailyByDate,
        data: responseDailyByDate,
        execute: executeDailyByDate
    } = useGetSubjectContentMetricsResult(GraphIntervalType.DAILY_BY_DATE)

    const {
        isLoading: isLoadingHourlyByDate,
        error: errorHourlyByDate,
        data: responseHourlyByDate,
        execute: executeHourlyByDate
    } = useGetSubjectContentMetricsResult(GraphIntervalType.HOURLY_BY_DATE)

    const {
        isLoading: isLoadingWeeklyByDate,
        error: errorWeeklyByDate,
        data: responseWeeklyByDate,
        execute: executeWeeklyByDate
    } = useGetSubjectContentMetricsResult(GraphIntervalType.WEEKLY_BY_DATE)

    const intervalMaps = {
        [GraphIntervalType.DAILY]: {
            loading: isLoadingDaily,
            error: errorDaily,
            data: dailyData,
            execute: executeDaily
        },
        [GraphIntervalType.HOURLY]: {
            loading: isLoadingHourly,
            error: errorHourly,
            data: hourlyData,
            execute: executeHourly
        },
        [GraphIntervalType.WEEKLY]: {
            loading: isLoadingWeekly,
            error: errorWeekly,
            data: weeklyData,
            execute: executeWeekly
        },
        [GraphIntervalType.DAILY_BY_DATE]: {
            loading: isLoadingDailyByDate,
            error: errorDailyByDate,
            data: dailyDataByDate,
            execute: executeDailyByDate
        },
        [GraphIntervalType.HOURLY_BY_DATE]: {
            loading: isLoadingHourlyByDate,
            error: errorHourlyByDate,
            data: hourlyDataByDate,
            execute: executeHourlyByDate
        },
        [GraphIntervalType.WEEKLY_BY_DATE]: {
            loading: isLoadingWeeklyByDate,
            error: errorWeeklyByDate,
            data: weeklyDataByDate,
            execute: executeWeeklyByDate
        }
    };

    const getIntervalChartDependencies = () => {
        const dependencies = []
        for (const chartKey of Object.keys(intervalMaps)) {
            dependencies.push(intervalMaps[chartKey].loading)
            dependencies.push(intervalMaps[chartKey].error)
            dependencies.push(intervalMaps[chartKey].data)
        }
        return dependencies
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        if (window) {
            window.addEventListener('resize', handleResize);
            handleResize();
        }

        // Call the handler right away so state gets updated with initial window size

        // Remove event listener on cleanup
        return () => {
            if (window) {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, []); // Empty array ensures that effect is only run on mount and unmount


    useEffect(() => {
        if (!isSubjectLoading) {
            executeDaily(dashboardId).then((response) => {
                const metrics = []
                if (response && response.metrics) {
                    for (const metric of response.metrics) {
                        metrics.push(processMetric(metric))
                    }
                }
                setDailyData(metrics)
            })
        }
        if (!isSubjectLoading) {
            executeHourly(dashboardId).then((response) => {
                const metrics = []
                if (response && response.metrics) {
                    for (const metric of response.metrics) {
                        metrics.push(processMetric(metric))
                    }
                }
                setHourlyData(metrics)
            })
        }
        if (!isSubjectLoading) {
            executeWeekly(dashboardId).then((response) => {
                const metrics = []
                if (response && response.metrics) {
                    for (const metric of response.metrics) {
                        metrics.push(processMetric(metric, true))
                    }
                }
                setWeeklyData(metrics)
            })
        }
    }, [isSubjectLoading, dashboardId]);

    useEffect(() => {
        if (!dateApi || isSubjectLoading) {
            return
        }
        executeHourlyByDate(dashboardId, dateApi).then((response) => {
            const metrics = []
            if (response && response.metrics) {
                for (const metric of response.metrics) {
                    metrics.push(processMetric(metric))
                }
            }
            setHourlyDataByDate(metrics)
        })
        executeDailyByDate(dashboardId, dateApi).then((response) => {
            const metrics = []
            if (response && response.metrics) {
                for (const metric of response.metrics) {
                    metrics.push(processMetric(metric))
                }
            }
            setDailyDataByDate(metrics)
        })
        executeWeeklyByDate(dashboardId, dateApi).then((response) => {
            const metrics = []
            if (response && response.metrics) {
                for (const metric of response.metrics) {
                    metrics.push(processMetric(metric, true))
                }
            }
            setWeeklyDataByDate(metrics)
        })
    }, [dateApi, isSubjectLoading, dashboardId]);


    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedDate(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            setIsDateError(false);
            setSelectedDate(date);
            setDateApi(e.currentTarget.value);
            setSelectedChartInterval(chartIntervalDropdown, true)
        } else {
            setIsDateError(true);
            setSelectedDate(undefined);
        }
    };


    const handleDaySelect = (
        closeCallBack: () => void
    ): SelectSingleEventHandler => {
        return (date, selectedDay, activeModifiers, e) => {
            setSelectedDate(date);
            if (date) {
                const dateString = format(date, "y-MM-dd");
                setInputDateValue(dateString);
                setIsDateError(false);
                setDateApi(dateString);
                setSelectedChartInterval(chartIntervalDropdown, true)
                closeCallBack();
            } else {
                setInputDateValue("");
            }
        };
    };

    const setSelectedChartInterval = (intervalSelection, doForceDate: boolean = false) => {
        if (!dateApi && !doForceDate) {
            setChartInterval(intervalSelection)
            return
        }
        if (intervalSelection === GraphIntervalType.WEEKLY) {
            setChartInterval(GraphIntervalType.WEEKLY_BY_DATE)
        }
        if (intervalSelection === GraphIntervalType.DAILY) {
            setChartInterval(GraphIntervalType.DAILY_BY_DATE)
        }
        if (intervalSelection === GraphIntervalType.HOURLY) {
            setChartInterval(GraphIntervalType.HOURLY_BY_DATE)
        }
    }


    const resetDate = (closeCallBack: () => void) => {
        setInputDateValue("");
        setSelectedDate(undefined);
        setIsDateError(false);
        setDateApi(null);
        setChartInterval(chartIntervalDropdown)
        closeCallBack()
    };


    const getCalendar = () => {
        let inputClass =
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md";
        if (isDateError) {
            inputClass =
                "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
        }
        return (
            <div className={"flex float-right mt-4"}>
                <div>
                    <Popover className="relative">
                        {({ open, close }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? "" : "text-opacity-90"}
                mb-0 pb-0 focus:outline-none group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <CalendarIcon
                                        className={
                                            "h-9 w-9 text-blue-600 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        }
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-full xl:-translate-x-1/2 transform px-4 sm:px-0">
                                        <div
                                            className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className={"flex-1 mt-1 mb-2"}>
                                                <label htmlFor="date-metrics" className="sr-only">
                                                    Enter Date in YYYY-MM-DD format
                                                </label>
                                                <div className="mt-1 relative rounded-md shadow-sm p-4">
                                                    <input
                                                        type="text"
                                                        name="date-metrics"
                                                        id="date-metrics"
                                                        className={inputClass}
                                                        placeholder={format(new Date(), "y-MM-dd")}
                                                        value={inputDateValue}
                                                        onChange={handleInputChange}
                                                        aria-invalid="true"
                                                        aria-describedby={isDateError ? "metric-date-error" : undefined}
                                                    />
                                                    {isDateError ? (
                                                        <div
                                                            className="absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none">
                                                            <ExclamationCircleIcon
                                                                className="h-6 w-6 text-red-500"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {isDateError ? (
                                                    <p className="text-sm text-red-600 absolute mb-2 pl-6 text-center"
                                                       id="metric-date-error">
                                                        Invalid date (YYYY-MM-DD format)
                                                    </p>
                                                ) : null}
                                            </div>
                                            <DayPicker
                                                initialFocus={open}
                                                mode="single"
                                                defaultMonth={selectedDate}
                                                selected={selectedDate}
                                                onSelect={handleDaySelect(close)}
                                            />
                                            <div className={"pb-3 pl-4"}>
                                                <button
                                                    type={"button"}
                                                    className="inline-flex items-center px-4 py-2  border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    onClick={() => {resetDate(close)}}>
                                                    Reset Date
                                                </button>
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
        );
    };


    const getChartWidth = () =>
        containerRef.current?.offsetWidth ?? 0 > 10
            ? containerRef.current?.offsetWidth ?? 500
            : 500;

    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200}/>
            </div>
        </div>
    );

    const noSummariesJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };


    const getChartMissingMessage = () => {
        if (isSubjectLoading) {
            return loadingJsx;
        }
        const chartInfo = intervalMaps[chartInterval];

        // Here you can handle loading, error, data state for the given intervalType
        if (chartInfo.loading) {
            // show loading state
            return loadingJsx;
        } else if (chartInfo.error) {
            // handle error state
            if (chartInfo.error === ErrorType.NotFound) {
                return noSummariesJsx('no data found');
            } else {
                return noSummariesJsx('an error occurred while retrieving metrics');
            }
        } else if (!chartInfo.data || chartInfo.data.length === 0) {
            // handle missing data state
            return noSummariesJsx('no data found');
        }
        return null
    }

    const getLegend = () => {
        if (!subjectData?.doSentiment && !topicId) {
            return null;
        }
        if (subChartType === SubGraphSelected.SENTIMENT) {
            return (
                <div className={"grid grid-cols-3 gap-3 text-sm pt-4 px-12 pl-32"}>
                    <div className={"flex"}>
                        <div className="mt-[1px] w-4 h-4 rounded-full bg-[#86EFAC] inline-block"></div><div className={"inline-block pl-2"}>% Positive sentiment</div>
                    </div>
                    <div className={"flex"}>
                        <div className="mt-[1px] w-4 h-4 rounded-full bg-[#FCA5A5] inline-block"></div><div className={"inline-block pl-2"}>% Negative sentiment</div>
                    </div>
                    <div className={"flex"}>
                        <div className="mt-[1px] w-4 h-4 rounded-full bg-[#BFDBFE] inline-block border border-[#3B82F6]"></div><div className={"inline-block pl-2"}>Net sentiment</div>
                    </div>
                </div>
            )
        }
        return (
            <div className={"grid grid-cols-3 gap-3 text-sm pt-4 px-12 pl-32"}>
                <div className={"flex text-center"}>
                    <div className="mt-[1px] w-4 h-4 rounded-full bg-[#86EFAC] inline-block"></div><div className={"inline-block pl-2"}>Positive sentiment</div>
                </div>
                <div className={"flex"}>
                    <div className="mt-[1px] w-4 h-4 rounded-full bg-[#FCA5A5] inline-block"></div><div className={"inline-block pl-2"}>Negative sentiment</div>
                </div>
                <div className={"flex"}>
                    <div className="mt-[1px] w-4 h-4 rounded-full bg-[#D4D4D4] inline-block"></div><div className={"inline-block pl-2"}>Neutral sentiment</div>
                </div>
            </div>
        )
    }

    const getTopicOptions = () => {
        const options: JSX.Element[] = []
        options.push(
            <option key={'0'} value={'0'}>- None -</option>
        )
        if (isSubjectLoading) {
            return options
        }
        const topics = subjectData?.topics
        if (!topics || topics.length === 0) {
            return options
        }
        for (const topic of topics) {
            options.push(
                <option key={`${topic.id}`} value={`${topic.id}`}>{topic.displayLabel}</option>
            )
        }
        return options
    }

    const getTopicFilter = () => {
        return (
            <>
                <label
                    htmlFor={'social-metrics-topics-filter'}
                    className="block text-sm font-medium text-gray-700"
                >
                    Topic
                </label>
                <select
                    defaultValue={`${topicId}`}
                    onChange={(e) => {
                        setTopicId(parseInt(e.target.value))
                    }}
                    name={'social-metrics-topics-filter'}
                    id={'social-metrics-topics-filter'}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md max-w-[230px]"
                >

                    {getTopicOptions()}
                </select>
            </>
        )
    }

    const getDataTypeOptions = () => {
        const options: JSX.Element[] = []
        options.push(
            <option key={'0'} value={'0'}>- All -</option>
        )
        if (isSubjectLoading) {
            return options
        }
        if (!enabledDataTypes || enabledDataTypes.length === 0) {
            return options
        }
        for (const dataType of enabledDataTypes) {
            options.push(
                <option key={`${dataType.id}`} value={`${dataType.id}`}>{dataType.label}</option>
            )
        }
        return options
    }

    const getDataTypeFilter = () => {
        if (subjectData?.doEnableArxiv) {
            return null
        }
        return (
            <>
                <label
                    htmlFor={'social-stream-data_type-filter'}
                    className="block text-sm font-medium text-gray-700"
                >
                    Source
                </label>
                <select
                    defaultValue={`${dataTypeId}`}
                    onChange={(e) => {
                        setDataTypeId(parseInt(e.target.value))
                    }}
                    name={'social-stream-data_type-filter'}
                    id={'social-stream-data_type-filter'}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md max-w-[230px]"
                >

                    {getDataTypeOptions()}
                </select>
            </>
        )
    }

    const getChartRef = () => {
        return useD3(
            (divParent) => {
                const noChart = getChartMissingMessage()
                if (noChart) {
                    divParent.selectAll("*").remove();
                    return
                }
                divParent.selectAll("*").remove();
                const margin = { top: 20, right: 30, bottom: 80, left: 65 };
                let width = getChartWidth() - margin.left - margin.right;
                let height = 450 - margin.top - margin.bottom;
                let data;
                if (chartInterval === GraphIntervalType.HOURLY) {
                    data = hourlyData
                } else if (chartInterval === GraphIntervalType.DAILY) {
                    data = dailyData
                } else if (chartInterval === GraphIntervalType.WEEKLY) {
                    data = weeklyData
                } else if (chartInterval === GraphIntervalType.DAILY_BY_DATE) {
                    data = dailyDataByDate
                } else if (chartInterval === GraphIntervalType.HOURLY_BY_DATE) {
                    data = hourlyDataByDate
                } else if (chartInterval === GraphIntervalType.WEEKLY_BY_DATE) {
                    data = weeklyDataByDate
                }

                // Create the x and y scales
                let xScale = d3.scaleBand()
                    .domain(data.map(d => d.date))
                    .range([0, width])
                    .padding(0.1);

                const graphInfo = getGraphInfo(chartType)
                const subGraphInfo =  getSubGraphInfo(subChartType)
                const getTopicMentionData = (d) => {
                    if (d.topics) {
                        const topic = d.topics.find(d => d.id === topicId)
                        return topic ? topic.m : 0;
                    }
                    return 0
                }

                const getTopicWithDataTypeMentionData = (d) => {
                    if (d.topics) {
                        const topic = d.topics.find(d => d.id === topicId)
                        if (!topic || !topic.dt || topic.dt.length === 0) {
                            return 0
                        }
                        const topicDataType = topic.dt.find(d => d.id === dataTypeId)
                        return topicDataType ? topicDataType.m : 0
                    }
                    return 0
                }

                const getDataTypeMentionData = (d) => {
                    if (d.dataTypes) {
                        const dataType = d.dataTypes.find(d => d.id === dataTypeId)
                        return dataType ? dataType.m : 0
                    }
                    return 0
                }

                const getMetricObjectData = (d, dataId, dataKey) => (d) => {
                    if (d[dataKey]) {
                        const dataItem = d[dataKey].find(d => d.id === dataId)
                        if (dataItem && dataItem.m) {
                            return {
                                m: dataItem.m,
                                pos: (dataItem.pos / dataItem.m) * 100,
                                neg: (dataItem.neg / dataItem.m) * 100,
                                neu: (dataItem.neu / dataItem.m) * 100,
                                sentiment: ((dataItem.pos - dataItem.neg) / dataItem.m) * 100,
                            }
                        }
                    }
                    return {
                        m: 0,
                        pos: 0,
                        neg: 0,
                        neu: 0,
                        sentiment: 0
                    }
                }

                const getTopicSentimentMentionData = (d) => {
                    if (d.topics) {
                        const topic = d.topics.find(d => d.id === topicId)
                        if (topic && topic.m) {
                            return ((topic.pos - topic.neg) / topic.m) * 100
                        }
                    }
                    return 0
                }

                const getDataTypeSentimentMentionData = (d) => {
                    if (d.dataTypes) {
                        const dataType = d.dataTypes.find(d => d.id === dataTypeId)
                        if (dataType && dataType.m) {
                            return ((dataType.pos - dataType.neg) / dataType.m) * 100
                        }
                    }
                    return 0
                }

                const getTopicObjectData = (d) => {
                    if (d.topics) {
                        const topic = d.topics.find(d => d.id === topicId)
                        if (topic && topic.m && isTopicSentiment(topic.id)) {
                            return {
                                m: topic.m,
                                pos: (topic.pos / topic.m) * 100,
                                neg: (topic.neg / topic.m) * 100,
                                neu: (topic.neu / topic.m) * 100,
                                sentiment: ((topic.pos - topic.neg) / topic.m) * 100,
                            }
                        }
                    }
                    return {
                        m: 0,
                        pos: 0,
                        neg: 0,
                        neu: 0,
                        sentiment: 0
                    }
                }

                const getDataTypeObjectData = (d) => {
                    if (d.dataTypes) {
                        const topic = d.dataTypes.find(d => d.id === dataTypeId)
                        if (topic && topic.m) {
                            return {
                                m: topic.m,
                                pos: (topic.pos / topic.m) * 100,
                                neg: (topic.neg / topic.m) * 100,
                                neu: (topic.neu / topic.m) * 100,
                                sentiment: ((topic.pos - topic.neg) / topic.m) * 100,
                            }
                        }
                    }
                    return {
                        m: 0,
                        pos: 0,
                        neg: 0,
                        neu: 0,
                        sentiment: 0
                    }
                }

                const getTopicDataTypeObjectData = (d) => {
                    const defaultObjectData = {
                        m: 0,
                        pos: 0,
                        neg: 0,
                        neu: 0,
                        sentiment: 0
                    }
                    if (d.topics) {
                        const topic = d.topics.find(d => d.id === topicId)
                        if (topic && topic.m && isTopicSentiment(topic.id)) {
                            const topicData = topic.dt.find(d => d.id === dataTypeId)
                            if (topicData && topicData.m) {
                                return {
                                    m: topicData.m,
                                    pos: (topicData.pos / topicData.m) * 100,
                                    neg: (topicData.neg / topicData.m) * 100,
                                    neu: (topicData.neu / topicData.m) * 100,
                                    sentiment: ((topicData.pos - topicData.neg) / topicData.m) * 100,
                                }
                            }
                        }
                    }
                    return defaultObjectData
                }

                const getMaxValueDomain = (max) => max ? max : 1

                let yScale
                if (subChartType === SubGraphSelected.MENTION && !isTopicSelected() && !isDataTypeSelected()) {
                    yScale = d3.scaleLinear()
                        .domain([0, getMaxValueDomain(d3.max(data, d => d[graphInfo.dataKey]))])
                        .range([height, 0]);
                }
                else if (subChartType === SubGraphSelected.MENTION && isTopicSelected() && !isDataTypeSelected()) {
                    yScale = d3.scaleLinear()
                        .domain([0, d3.max(data, getTopicMentionData) ? d3.max(data, getTopicMentionData) : 1])
                        .range([height, 0]);
                }
                else if (subChartType === SubGraphSelected.MENTION && isDataTypeSelected() && !isTopicSelected()) {
                    yScale = d3.scaleLinear()
                        .domain([0, d3.max(data, getDataTypeMentionData) ? d3.max(data, getDataTypeMentionData) : 1])
                        .range([height, 0]);
                }
                else if (subChartType === SubGraphSelected.MENTION && isDataTypeSelected() && isTopicSelected()) {
                    yScale = d3.scaleLinear()
                        .domain([0, d3.max(data, getTopicWithDataTypeMentionData) ? d3.max(data, getTopicWithDataTypeMentionData) : 1])
                        .range([height, 0]);
                }
                if (subChartType === SubGraphSelected.SENTIMENT) {
                    yScale = d3.scaleLinear()
                        .domain([-100, 100])
                        .range([height, 0]);
                }
                if (!yScale) {
                    yScale = d3.scaleLinear()
                        .domain([0, getMaxValueDomain(d3.max(data, d => d[graphInfo.dataKey]))])
                        .range([height, 0]);
                }
                const dateFormat = chartIntervalDropdown === GraphIntervalType.DAILY ? '%m-%d' : '%m-%d %H:%M'

                // Create the axes
                let xAxis = d3.axisBottom(xScale).tickFormat((interval,i) => {
                    return i%3 !== 0 ? " ": d3.timeFormat(dateFormat)(interval);
                })
                if (chartIntervalDropdown === GraphIntervalType.HOURLY) {
                    xAxis.tickFormat((interval,i) => {
                        return i%3 !== 0 ? " ": d3.timeFormat(dateFormat)(interval);
                    })
                } else if (chartIntervalDropdown === GraphIntervalType.WEEKLY) {
                    xAxis.tickFormat(d3.timeFormat('%m-%d (W%U)'))
                }
                else {
                    xAxis.tickFormat(d3.timeFormat(dateFormat))
                }

                let yAxis = d3.axisLeft(yScale);

                const body = divParent
                    .append("div")
                    .append("div")

                // Append the SVG container and add the graph group
                let svg = body.append("svg")
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    .append('g')
                    .attr('transform', `translate(${margin.left}, ${margin.top})`);

                if (subChartType == SubGraphSelected.MENTION && isTopicSelected() && !isDataTypeSelected()) {
                    svg.selectAll('rect')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(getTopicMentionData(d)))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => height - yScale(getTopicMentionData(d)))
                        .attr('fill', d => {
                            if (!isTopicSentiment(topicId)) {
                                return topicColor
                            }
                            if (getTopicSentimentMentionData(d) > 10) {
                                return "rgb(134 239 172)"
                            }
                            if (getTopicSentimentMentionData(d) < -10) {
                                return "rgb(252 165 165)"
                            }
                            return "rgb(212 212 212)"
                        });
                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text('Mentions');
                } else if (subChartType == SubGraphSelected.MENTION && !isTopicSelected() && isDataTypeSelected()) {
                    svg.selectAll('rect')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(getDataTypeMentionData(d)))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => height - yScale(getDataTypeMentionData(d)))
                        .attr('fill', d => {
                            if (getDataTypeSentimentMentionData(d) > 10) {
                                return "rgb(134 239 172)"
                            }
                            if (getDataTypeSentimentMentionData(d) < -10) {
                                return "rgb(252 165 165)"
                            }
                            return "rgb(212 212 212)"
                        });
                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text('Mentions');
                } else if (subChartType == SubGraphSelected.MENTION && isTopicSelected() && isDataTypeSelected()) {
                    svg.selectAll('rect')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(getTopicWithDataTypeMentionData(d)))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => height - yScale(getTopicWithDataTypeMentionData(d)))
                        .attr('fill', d => {
                            if (getTopicWithDataTypeMentionData(d) > 10) {
                                return "rgb(134 239 172)"
                            }
                            if (getTopicWithDataTypeMentionData(d) < -10) {
                                return "rgb(252 165 165)"
                            }
                            return "rgb(212 212 212)"
                        });
                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text('Mentions');
                } else if (subChartType == SubGraphSelected.SENTIMENT && isTopicSelected() && !isDataTypeSelected()) {
                    svg.selectAll('.positive')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(0))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(getTopicObjectData(d).neg) - yScale(0)))
                        .attr('fill', "rgb(252 165 165)");

                    svg.selectAll('negative')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(getTopicObjectData(d).pos))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(getTopicObjectData(d).pos) - yScale(0)))
                        .attr('fill', "rgb(134 239 172)");

                    // Sentiment circles
                    svg.selectAll('.sentiment-circle')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(getTopicObjectData(d).sentiment))
                        .attr('r', 5) // Set the circle radius
                        .attr('fill', 'rgb(59 130 246)');

                    svg.selectAll('.sentiment-circle-inner')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(getTopicObjectData(d).sentiment))
                        .attr('r', 4) // Set the circle radius
                        .attr('fill', 'rgb(191 219 254)');

                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(subGraphInfo.label);
                }  else if (subChartType == SubGraphSelected.SENTIMENT && !isTopicSelected() && isDataTypeSelected()) {
                    svg.selectAll('.positive')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(0))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(getDataTypeObjectData(d).neg) - yScale(0)))
                        .attr('fill', "rgb(252 165 165)");

                    svg.selectAll('negative')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(getTopicObjectData(d).pos))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(getDataTypeObjectData(d).pos) - yScale(0)))
                        .attr('fill', "rgb(134 239 172)");

                    // Sentiment circles
                    svg.selectAll('.sentiment-circle')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(getDataTypeObjectData(d).sentiment))
                        .attr('r', 5) // Set the circle radius
                        .attr('fill', 'rgb(59 130 246)');

                    svg.selectAll('.sentiment-circle-inner')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(getDataTypeObjectData(d).sentiment))
                        .attr('r', 4) // Set the circle radius
                        .attr('fill', 'rgb(191 219 254)');
                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(subGraphInfo.label);
                }  else if (subChartType == SubGraphSelected.SENTIMENT && isTopicSelected() && isDataTypeSelected()) {
                    svg.selectAll('.positive')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(0))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(getTopicDataTypeObjectData(d).neg) - yScale(0)))
                        .attr('fill', "rgb(252 165 165)");

                    svg.selectAll('negative')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(getTopicObjectData(d).pos))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(getTopicDataTypeObjectData(d).pos) - yScale(0)))
                        .attr('fill', "rgb(134 239 172)");

                    // Sentiment circles
                    svg.selectAll('.sentiment-circle')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(getTopicDataTypeObjectData(d).sentiment))
                        .attr('r', 5) // Set the circle radius
                        .attr('fill', 'rgb(59 130 246)');

                    svg.selectAll('.sentiment-circle-inner')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(getTopicDataTypeObjectData(d).sentiment))
                        .attr('r', 4) // Set the circle radius
                        .attr('fill', 'rgb(191 219 254)');
                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(subGraphInfo.label);
                } else if (subChartType !== SubGraphSelected.SENTIMENT) {

                    // Add the bars
                    svg.selectAll('rect')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(d[graphInfo.dataKey]))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => height - yScale(d[graphInfo.dataKey]))
                        .attr('fill', d => {
                            if (!subjectData?.doSentiment) {
                                return topicColor
                            }
                            if (chartType == GraphSelected.DATA_TYPE || chartType == GraphSelected.TOPIC) {
                                return topicColor
                            }
                            if (d.sentiment > 10) {
                                return "rgb(134 239 172)"
                            }
                            if (d.sentiment < -10) {
                                return "rgb(252 165 165)"
                            }
                            return "rgb(212 212 212)"
                        });
                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(chartType !== GraphSelected.TOPIC ? graphInfo.label : 'Mentions');
                } else {
                    // Add the bars

                    svg.selectAll('.positive')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(0))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(d.sentimentNegative) - yScale(0)))
                        .attr('fill', "rgb(252 165 165)");

                    svg.selectAll('negative')
                        .data(data)
                        .enter()
                        .append('rect')
                        .attr('x', d => xScale(d.date))
                        .attr('y', d => yScale(d.sentimentPositive))
                        .attr('width', xScale.bandwidth())
                        .attr('height', d => Math.abs(yScale(d.sentimentPositive) - yScale(0)))
                        .attr('fill', "rgb(134 239 172)");

                    // Sentiment circles
                    svg.selectAll('.sentiment-circle')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(d.sentiment))
                        .attr('r', 5) // Set the circle radius
                        .attr('fill', 'rgb(59 130 246)');

                    svg.selectAll('.sentiment-circle-inner')
                        .data(data)
                        .enter()
                        .append('circle')
                        .attr('class', 'sentiment-circle')
                        .attr('cx', d => xScale(d.date) + xScale.bandwidth() / 2)
                        .attr('cy', d => yScale(d.sentiment))
                        .attr('r', 4) // Set the circle radius
                        .attr('fill', 'rgb(191 219 254)');

                    // Add the y-axis
                    svg.append('g')
                        .call(yAxis);
                    // Add y-axis label
                    svg.append('text')
                        .attr('transform', 'rotate(-90)')
                        .attr('y', 0 - margin.left)
                        .attr('x', 0 - height / 2)
                        .attr('dy', '1em')
                        .style('text-anchor', 'middle')
                        .text(graphInfo.label);
                }

                // Add the x-axis
                svg.append('g')
                    .attr('transform', `translate(0, ${height})`)
                    .call(xAxis)
                    .selectAll('text')
                    .style('text-anchor', 'end')
                    .attr('dx', '-0.8em')
                    .attr('dy', '-0.55em')
                    .attr('transform', 'rotate(-90)');

                // Add x-axis label
                svg.append('text')
                    .attr('x', width / 2)
                    .attr('y', height + margin.bottom)
                    .style('text-anchor', 'middle')
                    .text('Date');
            },
            [
                chartType,
                chartInterval,
                topicId,
                dateApi,
                dataTypeId,
                windowSize,
                subChartType,
                ...getIntervalChartDependencies()
            ]
        );
    };

    const getToggleOptions = () => {
        const options: JSX.Element[] = [];
        for (const toggleOption of graphInfoList) {
            options.push(
                <option
                    key={toggleOption.selectKey}
                    value={toggleOption.selectKey}
                >
                    {toggleOption.label}
                </option>
            );
        }
        return options;
    };

    const getSubChartToggleOptions = () => {
        const options: JSX.Element[] = [];
        for (const toggleOption of subGraphInfoList) {
            options.push(
                <option
                    key={toggleOption.selectKey}
                    value={toggleOption.selectKey}
                >
                    {toggleOption.label}
                </option>
            );
        }
        return options;
    };

    // const isSubChartSentimentDisplayed = () => {
    //     if (chartType !== GraphSelected.DATA_TYPE && chartType !== GraphSelected.TOPIC) {
    //         return false;
    //     }
    //     if (chartType === GraphSelected.TOPIC && (!topicId || !isTopicSentiment(topicId))) {
    //         return false;
    //     }
    //     return !(chartType === GraphSelected.DATA_TYPE && !dataTypeId);
    // }


    const getSubChartFilter = () => {
        if (subjectData?.doEnableArxiv) {
            return null;
        }
        return (
            <div>
                <label
                    htmlFor={`chart`}
                    className="block text-sm font-medium text-gray-700"
                >
                    Graph Type
                </label>
                <select
                    defaultValue={subChartType}
                    onChange={(e) =>
                        setSubChartType(e.target.value)
                    }
                    name={`chart`}
                    id={`chart`}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                    {getSubChartToggleOptions()}
                </select>
            </div>
        )
    }

    const getDropDown = () => {
        return (
            <div className={"flex"}>
                <div className={"mr-2"}>
                    {getDataTypeFilter()}
                </div>
                <div className={"mr-2"}>
                    {getTopicFilter()}
                </div>
                <div className={"mr-2"}>
                    <label
                        htmlFor={'chart-interval'}
                        className="block text-sm font-medium text-gray-700"
                    >
                        Interval
                    </label>
                    <select
                        defaultValue={chartIntervalDropdown}
                        onChange={(e) => {
                            setChartIntervalDropdown(e.target.value)
                            setSelectedChartInterval(e.target.value)
                        }}
                        name={'chart-interval'}
                        id={'chart-interval'}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    >
                        <option value={GraphIntervalType.HOURLY}>Hourly</option>
                        <option value={GraphIntervalType.DAILY}>Daily</option>
                        <option value={GraphIntervalType.WEEKLY}>Weekly</option>
                    </select>
                </div>
                <div>
                    {getSubChartFilter()}
                </div>
                <div>
                    {getCalendar()}
                </div>
            </div>
        );
    };

    const chartRef = getChartRef() as MutableRefObject<HTMLDivElement>;


    return (
        <>
        <TileRounded tileType={"main"}>
                <div className={"flex-grow pt-6"}>
                    <span className={"text-xl"}>Metrics</span>
                </div>
                <div className={"flex"}>
                    <div className={"flex-grow pt-6"}>
                    </div>
                    <div className={"pt-1"}>
                        {getDropDown()}
                    </div>
                </div>
                <div>
                    {getLegend()}
                </div>
                <div className={"pt-3 pb-3"} ref={containerRef}>
                    {getChartMissingMessage()}
                    <div ref={chartRef!}></div>
                </div>
            </TileRounded>
        </>
    );
};

export default ContentDashboardMetrics;
