import React from "react";
import type { PageProps } from "gatsby";
import WithAuth from "../components/user/WithAuth";
import DashboardViewPage from "../components/dashboards/DashboardViewPage";
import BaseLayout from "../components/layout/BaseLayout";
import GtagUserSetter from "../components/user/GtagUserSetter";
import AmplitudeUserSetter from "../components/user/AmplitudeUserSetter";

const DashboardViewRoute = (props: PageProps) => {

    return (
        <WithAuth>
            <GtagUserSetter />
            <AmplitudeUserSetter />
            <BaseLayout {...props}>
                <DashboardViewPage {...props} />
            </BaseLayout>
        </WithAuth>
    )

};


export const Head = () => {
    return (
        <>
            <title>Runic | Dashboard View</title>
        </>
    )
}


export default DashboardViewRoute;
